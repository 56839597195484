import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Contact = () => {
  return (
    <Layout>
      <SEO title="obvıous. careers" />
      <div className="relative pb-12">
        <div className="absolute z-0 md:z-10 inset-0 opacity-10 pointer-events-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="3724"
            height="609"
            viewBox="0 0 3724 609"
          >
            <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1">
              <g transform="translate(1209)">
                <circle cx="1187" cy="151" r="24" fill="#00B1A9" />
                <circle cx="642" cy="158" r="20" fill="#00B1A9" />
                <circle cx="1062" cy="212" r="36" fill="#75DDDD" />
                <circle cx="131" cy="250" r="34" fill="#00B1A9" />
                <circle cx="403" cy="562" r="19" fill="#00B1A9" />
                <circle cx="707" cy="253" r="35" fill="#00B1A9" />
                <circle cx="285" cy="199" r="43" fill="#00B1A9" />
                <circle cx="129" cy="74" r="28" fill="#75DDDD" />
                <circle cx="928" cy="256" r="35" fill="#508991" />
                <circle cx="477" cy="279" r="34" fill="#00B1A9" />
                <circle cx="57" cy="532" r="42" fill="#75DDDD" />
                <circle cx="1047" cy="373" r="38" fill="#00B1A9" />
                <circle cx="172" cy="558" r="23" fill="#00B1A9" />
                <circle cx="20" cy="278" r="20" fill="#508991" />
                <circle cx="671" cy="550" r="26" fill="#508991" />
                <circle cx="367" cy="168" r="11" fill="#75DDDD" />
                <circle cx="840" cy="48" r="48" fill="#00B1A9" />
                <circle cx="261" cy="482" r="20" fill="#508991" />
                <circle cx="828" cy="50" r="29" fill="#508991" />
                <circle cx="891" cy="467" r="40" fill="#75DDDD" />
                <circle cx="442" cy="214" r="18" fill="#508991" />
                <circle cx="536" cy="264" r="29" fill="#508991" />
                <circle cx="104" cy="344" r="49" fill="#75DDDD" />
                <circle cx="703" cy="571" r="38" fill="#508991" />
                <circle cx="278" cy="313" r="33" fill="#00B1A9" />
                <circle cx="1089" cy="237" r="38" fill="#75DDDD" />
                <circle cx="58" cy="374" r="50" fill="#75DDDD" />
                <circle cx="602" cy="465" r="42" fill="#00B1A9" />
                <circle cx="608" cy="507" r="29" fill="#508991" />
                <circle cx="555" cy="109" r="13" fill="#00B1A9" />
                <circle cx="1167" cy="44" r="42" fill="#00B1A9" />
                <circle cx="221" cy="413" r="36" fill="#00B1A9" />
                <circle cx="1042" cy="165" r="18" fill="#508991" />
                <circle cx="666" cy="279" r="30" fill="#75DDDD" />
                <circle cx="1063" cy="100" r="26" fill="#508991" />
                <circle cx="1195" cy="466" r="12" fill="#75DDDD" />
                <circle cx="1008" cy="411" r="36" fill="#00B1A9" />
                <circle cx="1216" cy="464" r="26" fill="#00B1A9" />
                <circle cx="287" cy="560" r="12" fill="#508991" />
                <circle cx="119" cy="238" r="24" fill="#75DDDD" />
                <circle cx="328" cy="571" r="14" fill="#508991" />
                <circle cx="888" cy="456" r="38" fill="#508991" />
                <circle cx="906" cy="73" r="16" fill="#00B1A9" />
                <circle cx="799" cy="257" r="41" fill="#00B1A9" />
                <circle cx="965" cy="367" r="11" fill="#75DDDD" />
                <circle cx="504" cy="50" r="12" fill="#75DDDD" />
                <circle cx="891" cy="379" r="21" fill="#75DDDD" />
                <circle cx="629" cy="218" r="11" fill="#508991" />
                <circle cx="757" cy="500" r="40" fill="#75DDDD" />
              </g>
              <g transform="matrix(1 0 0 -1 2482 609)">
                <circle cx="1187" cy="151" r="24" fill="#00B1A9" />
                <circle cx="642" cy="158" r="20" fill="#00B1A9" />
                <circle cx="1062" cy="212" r="36" fill="#75DDDD" />
                <circle cx="131" cy="250" r="34" fill="#00B1A9" />
                <circle cx="403" cy="562" r="19" fill="#00B1A9" />
                <circle cx="707" cy="253" r="35" fill="#00B1A9" />
                <circle cx="285" cy="199" r="43" fill="#00B1A9" />
                <circle cx="129" cy="74" r="28" fill="#75DDDD" />
                <circle cx="928" cy="256" r="35" fill="#508991" />
                <circle cx="477" cy="279" r="34" fill="#00B1A9" />
                <circle cx="57" cy="532" r="42" fill="#75DDDD" />
                <circle cx="1047" cy="373" r="38" fill="#00B1A9" />
                <circle cx="172" cy="558" r="23" fill="#00B1A9" />
                <circle cx="20" cy="278" r="20" fill="#508991" />
                <circle cx="671" cy="550" r="26" fill="#508991" />
                <circle cx="367" cy="168" r="11" fill="#75DDDD" />
                <circle cx="840" cy="48" r="48" fill="#00B1A9" />
                <circle cx="261" cy="482" r="20" fill="#508991" />
                <circle cx="828" cy="50" r="29" fill="#508991" />
                <circle cx="891" cy="467" r="40" fill="#75DDDD" />
                <circle cx="442" cy="214" r="18" fill="#508991" />
                <circle cx="536" cy="264" r="29" fill="#508991" />
                <circle cx="104" cy="344" r="49" fill="#75DDDD" />
                <circle cx="703" cy="571" r="38" fill="#508991" />
                <circle cx="278" cy="313" r="33" fill="#00B1A9" />
                <circle cx="1089" cy="237" r="38" fill="#75DDDD" />
                <circle cx="58" cy="374" r="50" fill="#75DDDD" />
                <circle cx="602" cy="465" r="42" fill="#00B1A9" />
                <circle cx="608" cy="507" r="29" fill="#508991" />
                <circle cx="555" cy="109" r="13" fill="#00B1A9" />
                <circle cx="1167" cy="44" r="42" fill="#00B1A9" />
                <circle cx="221" cy="413" r="36" fill="#00B1A9" />
                <circle cx="1042" cy="165" r="18" fill="#508991" />
                <circle cx="666" cy="279" r="30" fill="#75DDDD" />
                <circle cx="1063" cy="100" r="26" fill="#508991" />
                <circle cx="1195" cy="466" r="12" fill="#75DDDD" />
                <circle cx="1008" cy="411" r="36" fill="#00B1A9" />
                <circle cx="1216" cy="464" r="26" fill="#00B1A9" />
                <circle cx="287" cy="560" r="12" fill="#508991" />
                <circle cx="119" cy="238" r="24" fill="#75DDDD" />
                <circle cx="328" cy="571" r="14" fill="#508991" />
                <circle cx="888" cy="456" r="38" fill="#508991" />
                <circle cx="906" cy="73" r="16" fill="#00B1A9" />
                <circle cx="799" cy="257" r="41" fill="#00B1A9" />
                <circle cx="965" cy="367" r="11" fill="#75DDDD" />
                <circle cx="504" cy="50" r="12" fill="#75DDDD" />
                <circle cx="891" cy="379" r="21" fill="#75DDDD" />
                <circle cx="629" cy="218" r="11" fill="#508991" />
                <circle cx="757" cy="500" r="40" fill="#75DDDD" />
              </g>
              <circle cx="1187" cy="151" r="24" fill="#00B1A9" />
              <circle cx="642" cy="158" r="20" fill="#00B1A9" />
              <circle cx="1062" cy="212" r="36" fill="#75DDDD" />
              <circle cx="131" cy="250" r="34" fill="#00B1A9" />
              <circle cx="403" cy="562" r="19" fill="#00B1A9" />
              <circle cx="707" cy="253" r="35" fill="#00B1A9" />
              <circle cx="285" cy="199" r="43" fill="#00B1A9" />
              <circle cx="129" cy="74" r="28" fill="#75DDDD" />
              <circle cx="928" cy="256" r="35" fill="#508991" />
              <circle cx="477" cy="279" r="34" fill="#00B1A9" />
              <circle cx="57" cy="532" r="42" fill="#75DDDD" />
              <circle cx="1047" cy="373" r="38" fill="#00B1A9" />
              <circle cx="172" cy="558" r="23" fill="#00B1A9" />
              <circle cx="20" cy="278" r="20" fill="#508991" />
              <circle cx="671" cy="550" r="26" fill="#508991" />
              <circle cx="367" cy="168" r="11" fill="#75DDDD" />
              <circle cx="840" cy="48" r="48" fill="#00B1A9" />
              <circle cx="261" cy="482" r="20" fill="#508991" />
              <circle cx="828" cy="50" r="29" fill="#508991" />
              <circle cx="891" cy="467" r="40" fill="#75DDDD" />
              <circle cx="442" cy="214" r="18" fill="#508991" />
              <circle cx="536" cy="264" r="29" fill="#508991" />
              <circle cx="104" cy="344" r="49" fill="#75DDDD" />
              <circle cx="703" cy="571" r="38" fill="#508991" />
              <circle cx="278" cy="313" r="33" fill="#00B1A9" />
              <circle cx="1089" cy="237" r="38" fill="#75DDDD" />
              <circle cx="58" cy="374" r="50" fill="#75DDDD" />
              <circle cx="602" cy="465" r="42" fill="#00B1A9" />
              <circle cx="608" cy="507" r="29" fill="#508991" />
              <circle cx="555" cy="109" r="13" fill="#00B1A9" />
              <circle cx="1167" cy="44" r="42" fill="#00B1A9" />
              <circle cx="221" cy="413" r="36" fill="#00B1A9" />
              <circle cx="1042" cy="165" r="18" fill="#508991" />
              <circle cx="666" cy="279" r="30" fill="#75DDDD" />
              <circle cx="1063" cy="100" r="26" fill="#508991" />
              <circle cx="1195" cy="466" r="12" fill="#75DDDD" />
              <circle cx="1008" cy="411" r="36" fill="#00B1A9" />
              <circle cx="1216" cy="464" r="26" fill="#00B1A9" />
              <circle cx="287" cy="560" r="12" fill="#508991" />
              <circle cx="119" cy="238" r="24" fill="#75DDDD" />
              <circle cx="328" cy="571" r="14" fill="#508991" />
              <circle cx="888" cy="456" r="38" fill="#508991" />
              <circle cx="906" cy="73" r="16" fill="#00B1A9" />
              <circle cx="799" cy="257" r="41" fill="#00B1A9" />
              <circle cx="965" cy="367" r="11" fill="#75DDDD" />
              <circle cx="504" cy="50" r="12" fill="#75DDDD" />
              <circle cx="891" cy="379" r="21" fill="#75DDDD" />
              <circle cx="629" cy="218" r="11" fill="#508991" />
              <circle cx="757" cy="500" r="40" fill="#75DDDD" />
            </g>
          </svg>
        </div>
        <div className="relative pt-64 max-w-3xl text-center mx-auto">
          <h1 className="font-semibold text-2xl md:text-4xl text-white">
            Junior Web Developer<span className="text-brand-grey-dark">.</span>
          </h1>
          <p className="font-medium text-base md:text-lg mt-1 leading-normal text-brand-grey-dark max-w-2xl mx-auto uppercase">
            Cochrane, Alberta
          </p>
        </div>
      </div>
      <div className="flex flex-col flex-grow relative">
        <div className="w-full bg-brand-teal absolute top-0 z-0">
          <svg
            viewBox="0 0 50 15"
            preserveAspectRatio="none"
            className="w-full bottom-0 h-32 md:h-76"
            style={{ marginBottom: "-1px" }}
          >
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <path
                d="M0,14.9954241 C19.9497878,5.17408693 35.0012698,0.175684169 49.965204,0 C49.9695893,5.37969228 50.0383731,14.9954241 49.9695893,14.9954241 C38.8635973,14.9954241 22.2070676,14.9954241 0,14.9954241 Z"
                fill="#E8FFFE"
                fill-rule="nonzero"
              ></path>
            </g>
          </svg>
        </div>
        <div className={`h-full flex-grow bg-brand-teal-light pb-16`}>
          <div className="max-w-4xl mx-auto relative px-4 md:px-0">
            <div className="bg-white md:rounded-xl md:shadow-lg w-full relative z-20 px-6 py-12">
              <p className="leading-loose mb-4">
                Obvious is a rapidly growing Platform-as-a-Service startup,
                recently incubated within the full-stack development powerhouse
                that is 4iiii Innovations. Well known in the cycling world for
                their innovative and ultra-accurate performance sporting
                sensors, 4iiii has turned its eyes on the Internet of Things
                market with the creation of Obvious and its sister business,
                Hawkiiii.
              </p>
              <p className="leading-loose mb-4">
                We want everyone to feel engaged at Obvious, and we’ll take care
                of you while you develop amazing technologies. We have fresh,
                locally roasted coffee to fuel your work on a top of the line
                MacBook. To feed a good work/life balance (and we encourage
                it!), employees can participate in lunch hour sports every day
                of the week. We also offer an excellent health benefit plan to
                round it off.
              </p>
              <p className="leading-loose mb-4">
                We’re seeking a full-time permanent Junior Web Developer to join
                our team in Cochrane, Alberta.
              </p>
              <div className="mt-8 mb-4">
                <h2 className="text-brand-teal font-bold">WHAT YOU’LL DO </h2>
              </div>
              <p className="leading-loose mb-4">
                The Obvious Platform is a completely new approach to the
                embedded device lifecycle. To give the platform the ease of use
                and appeal it needs to reach mass-market penetration, we need an
                absolutely stellar, motivated junior web developer. You’ll work
                shoulder-to-shoulder with the rest of our dedicated team to
                design the interfaces that make sense of the back-end magic, and
                report to our lead web developer to learn, implement, and
                contribute to the framework we’ve spent a year refining. Since
                we’re a small team wearing many hats, there may be opportunities
                to contribute to projects outside of your standard scope of
                work, interface with clients, or travel to events.
              </p>
              <div className="mt-8 mb-4">
                <h2 className="text-brand-teal font-bold">
                  WHO WE’RE LOOKING FOR
                </h2>
              </div>
              <ul className="bullet">
                <li>
                  Working with our team to turn mockups into useable interfaces
                </li>
                <li>
                  Curating efficient UX to develop dynamic, highly interactive
                  web-applications
                </li>
                <li>Moving fast and committing quality code every day</li>
                <li>Learning new tools to streamline your own workflow</li>
                <li>
                  Applying a deep knowledge of JS and common libraries, CSS, and
                  front-end frameworks
                </li>
                <li>
                  Being responsible for your own deliverables while being
                  accountable to your team
                </li>
                <li>
                  Using a keen eye for detail to ensure a quality user
                  experience
                </li>
              </ul>
              <div className="mt-8 mb-4">
                <p>
                  If your past experience covers some/all of the following,
                  you’re probably well-suited for the role:
                </p>
              </div>
              <ul className="bullet">
                <li>Developing with React.js</li>
                <li>Developing with GraphQL</li>
                <li>Developing with Node.js</li>
                <li>Using Apollo Client</li>
                <li>Knowledge of ES6/7</li>
                <li>Knowledge of Python and Django</li>
                <li>Knowledge of mySQL</li>
              </ul>
              <div className="mt-8 mb-4">
                <h2 className="text-brand-teal font-bold">APPLY TODAY!</h2>
              </div>
              <p className="leading-loose mb-4">
                Email{" "}
                <a className="text-brand-teal" href="mailto:hr@obvious.xyz">
                  hr@obvious.xyz
                </a>{" "}
                with a cover letter, resume and LinkedIn profile. In addition,
                please share some of your best work with us! This could be your
                dribbble or GitHub profile, or direct links to public-facing
                projects. Including your best programming joke may earn you
                bonus points.
              </p>
              <p className="leading-loose mb-4">
                We thank all applicants for their interest, however, only those
                selected for an interview will be contacted. Given the busy-ness
                of the holiday season, the start date for this position will be
                in the new year.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-brand-teal-light -mb-1">
          <svg
            version="1.1"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            className="h-24 w-full"
          >
            <g fill="#101821">
              <path d="M0 100 C40 0 60 0 100 100 Z"></path>
            </g>
          </svg>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
